

.Login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Login-button {
    margin-top: 1rem;
}