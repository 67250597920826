.CreateUser {
    display: flex;
    flex-direction: column;
}

.CreateUser > *:last-child {
    margin-top: 2rem;
}

.Usage {
    display: flex;
    flex-direction: column;
    width: 60vw;
    max-height: 80vh;
    overflow-y: scroll;
}

.UsageOfUser {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: auto auto auto auto;
}

.UsageOfUser-total {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.UsageOfUser-total * {
    margin: 0;
}

.UsageOfUser-total p {
    font-size: 0.8rem;
}

.UsageOfUser-total h3 {
    font-size: 2rem;
}

.UsageOfUser-element {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
}

.UsageOfUser-count {
    font-size: larger;
    font-weight: 700;
    margin-right: 0.5rem;
}

.UsageOfUser-date {
    font-size: small;
    font-weight: 300;
}

.UserPicker {
    display: flex;
    justify-content: center;
    align-items: center;
}

.UserPicker-container > * {
    margin: 0.1rem 0.2rem;
}

.Users {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem 1rem;
}

.User-container {
    flex: 1;
}

.User {
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
}

.User-userName {
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 0.3rem;
    display: flex;
    justify-content: space-between;
}

.User-userName img {
    height: 1rem;
    cursor: pointer;
}

.User-attributes {
    display: grid;
    grid-template-columns: auto auto;
}

.User-attribute {
    display: flex;
    flex-direction: row;
}

.User-attributeName {
    margin-right: 0.5rem;
    font-size: 0.8rem;
    font-weight: 300;
}
