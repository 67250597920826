.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f2f3f8;
}

.App-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding-bottom: 6rem;
}

.App-button {
    transition: all 0.2s;
    background-color: var(--ligher-blue);
    color: var(--darker-orange);
    border-radius: 1.5rem;
    font-weight: 800;
    text-align: center;
    padding: 0.5rem 1.5rem;
    display: inline-block;
}

.App-button-deactive {
    background-color: var(--gray);
    padding: 0.5rem 1.5rem;
    border-radius: 1.5rem;
    font-weight: 400;
    text-align: center;
    display: inline-block;
}

.App-button img {
    height: 1rem;
    margin-left: 0.9rem;
}

.App-button:active {
    background-color: var(--darker-blue);
    color: var(--lighter-orange);
    transform: scale(0.98);
}

.App-button:hover {
    background-color: var(--darker-blue);
    color: var(--lighter-orange);
    transform: scale(1.02);
    cursor: pointer;
}

.App-background {
    width: 100vw;
    position: absolute;
    bottom: -8vw;
    right: 0;
    opacity: 0.5;
}

.App-bg-container {
    z-index: -1;
    position: absolute;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.App-blok {
    padding: 1.5rem;
    margin: 1rem;
    border-radius: 1rem;
    max-width: 80vw;
}

.App-glass {
    /* backdrop-filter: saturate(180%) blur(5px);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(240, 240, 240, 0.6);
    opacity: 0.9; */

    background-color: white;
}

.App-center {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
}

.Loading {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.App-notDropped {
    transform: scaleY(0);
    transform-origin: top;
    max-height: 0;
    margin: 0;
    overflow: hidden;
    transition: 0.3s ease-out;
}

.App-code {
    background-color: rgb(85, 85, 85);
    color: white;
    border-radius: 0.7rem;
    padding: 1rem;
}
