body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    min-height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

:root {
    --darker-blue: rgb(51, 98, 115);
    --ligher-blue: rgb(125, 155, 165);
    --darker-orange: rgb(212, 68, 36);
    --lighter-orange: rgb(236, 92, 52);
    --gray: rgb(227, 228, 236);
}

#root {
    min-height: 100vh;
}