.Header {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    padding: 0.5rem 4rem;
}

@media only screen and (max-width: 600px) {
    .Header {
        flex-direction: column;
    }
}

.Header h1 {
    font-weight: 500;
    font-style: normal;
    text-decoration: overline;
    font-size: 1.7rem;
}

.Header h3 {
    font-size: smaller;
    font-weight: 400;
}

.Header-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.Header-logo {
    height: 1.5rem;
}

.Header-left {
    display: flex;
    align-items: center;
}

.Header-left img {
    height: 1.5rem;
    margin-left: 1.5rem;
    cursor: pointer;
}


