.Validate {
    display: flex;
    flex-direction: row;
    width: 98%;
    justify-content: center;
}

.Validate-options {
    display: flex;
    flex-direction: column;
}

.ValidationViewer {
    flex-grow: 1;
}

.File-scroll {
    max-height: 15rem;
    overflow-y: scroll;
}

.Validate-markAsFinished {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Validate-explanation {
    max-width: 20rem;
    /* max-height: 15rem; */
    overflow-y: scroll;
}

.Validate-showSideBArButton {
    height: 2rem;
    margin: 0 1rem;
    cursor: pointer;
}
