.Button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Choose {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem 1.5rem;
    width: 100%;
}

.Choose-option {
    border-radius: 0.5rem;
    padding: 0.5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.Choose-option[data-checked="true"] {
    border: 2px solid black;
}

.TextInput {
    display: flex;
    justify-content: end;
}

.TextInput-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
}
.TextInput input {
    margin-top: 0.7rem;
    margin-bottom: 1.2rem;
    min-width: 12rem;
}

.NumberInput {
    display: flex;
    justify-content: end;
}

.NumberInput-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
}
.NumberInput input {
    margin-top: 0.7rem;
    margin-bottom: 1.2rem;

}
