.Editor {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
}

.Entries textarea {
    height: 0;
    resize: none;
    font-size: 0.9rem;
    border-radius: 0.2rem;
    border-width: 0.1px;
}

.Entries label {
    font-size: small;
}

.Entries {
    display: grid;
    grid-template-columns: max-content max-content max-content;
    grid-gap: 5px;
}

.Entries label {
    text-align: right;
}

.FileViewer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.FileViewer-container {
    flex-grow: 1;
    overflow: scroll;
}

.ChooseFile {
    display: flex;
    justify-content: space-between;
}

.AddRemoveButton {
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.SimpleFileViewer {
    flex-grow: 1;
}

.SimpleFileViewer object {
    width: 100%;
    height: 100%;
}