

.Form-form {
    display: grid;
    grid-template-columns: auto auto;
}

@media only screen and (max-width: 600px) {
    .Form-form {
        flex-direction: column;
    }
}

.Form-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Form-options label {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Form-radios {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.Form-filetype {
    height: 1.9rem;
}

.Form-filetype:hover {
    cursor: pointer;
}

.Form-filetype[data-checked="true"] {
    border: 2px solid black;
}

.Form-file {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    min-width: 10rem;
    position: relative;
}

.Form-file p {
    margin: 0.6rem;
}

.Form-file img {
    width: 4rem;
    padding-bottom: 1.5rem;
}

.Form-swap {
    position: absolute;
    top: 0.7rem;
    right: 0.7rem; 
}

.Form-swap img {
    width: 1.5rem;
}

.Form-clickable:hover {
    background-color: rgb(221, 222, 222);
    cursor: pointer;
}

.Form-spinner {
    padding-top: 3rem;
    /* display: inline-block; */
    width: 80px;
    height: 80px;
}

.Form-submit {
    display: flex;
    justify-content: center;
}

.Form-spinner:after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid black;
    border-color: black transparent black transparent;
    animation: Form-spinner 1.2s linear infinite;
}

@keyframes Form-spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}



.Fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    transition: 0.9s;
}

.Field {
    cursor: pointer;
    margin: 0.1rem;
}

.Field-active {
    color: black;
}

.Field-inactive {
    color: gray;
}

.DocumentType-input {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.DocumentType-type {
    display: flex;
}

.DocumentType-type img {
    margin-left: 1rem;
    height: 1rem;
}
