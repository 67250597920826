.blok-header {
    font-size: medium;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
}

.blok-content {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DropDownBlok {
    display: flex;
    flex-direction: column;
}

.DropDownBlok-headerToggle {
    cursor: pointer;
}

.DropDownBlok-headerToggle img {
    height: 1rem;
    margin-left: 2rem;
}

.ErrorBlok {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ErrorBlok-content {
    max-width: 15rem;
}

.ButtonBlok {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ErrorBlok-closeButton {
    position: absolute;
    right: 90%;
    top: 10%;
    height: 1rem;
}
