.Settings {
    display: grid;
    grid-template-columns: auto auto;
}

.Documents > * {
    margin-top: 2rem;
}

.Documents > *:first-child {
    margin-top: 0;
}

.UserInfo img {
    height: 1rem;
    cursor: pointer;
    margin-left: 0.5rem;
}

.UserInfo-attribute {
    display: flex;
    flex-direction: row;
}

.Documents-newDocInputContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Train {
    max-width: 17rem;
}

.Train-buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
}

.Settings-missingAnnotations {
    display: grid;
    gap: 0 0.5rem;
    grid-template-columns: 1fr 1fr;
}

.Settings-missingAnnotations div {
    display: flex;
    justify-content: space-between;
}

.Settings-missingAnnotationsLabel {

}

.Settings-missingAnnotationsCount {
    font-weight: 600;
}
